// Chakra imports
import {
    Box,
    Flex,
    Text,
    Icon,
    useColorModeValue,
    Checkbox,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/card/Card.js";
  import Menu from "components/menu/MainMenu";
  import IconBox from "components/icons/IconBox";
  import CheckTable from "./CheckTable";
  import {
    columnsDataCheck,
    columnsDataNews,
  } from "views/admin/default/variables/columnsData";
  
  // Assets
  import { MdDragIndicator } from "react-icons/md";
  import React, { useState, useEffect } from 'react';
  import { IoMdEye } from "react-icons/io";

  //API calls
import { latestCryptoNewsByViews } from 'api/ApiUtils';
  
  export default function NewsItemByViews(props) {
    const { ...rest } = props;

  const [cryptoNews, setCryptoNews] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [recordsCount, setRecordsCount] = useState(0)
  const [limit, setLimit] = useState(50)
  const [Pages, setPages] = useState(0)

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await latestCryptoNewsByViews(pageNo, limit)
      // console.log(response.data.data.news_feeds.news_feeds);
      setCryptoNews(response.data.data.news_feeds.news_feeds)
      setRecordsCount(response.data.data.news_feeds.total_news_feeds)
      setPages(response.data.data.news_feeds.pages)
    }
    fetchMyAPI()
  }, [])
  
    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
    const brandColor = useColorModeValue("brand.500", "brand.400");
    return (
      <Card p='20px' align='center' direction='column' w='100%' {...rest}>
        <CheckTable columnsData={columnsDataNews} tableData={cryptoNews}/>
        {()=>{console.log(cryptoNews);}}
        <Flex alignItems='center' w='100%' mb='30px'>
          <IconBox
            me='12px'
            w='38px'
            h='38px'
            bg={boxBg}
            icon={<Icon as={IoMdEye} color={brandColor} w='24px' h='24px' />}
          />
  
          <Text color={textColor} fontSize='lg' fontWeight='700'>
           News items by views
          </Text>
          <Menu ms='auto' />
        </Flex>

        <Box px='11px'>
          {cryptoNews?.map((newsItem, i) => {

            return (

                <Flex mb='20px'>
            <Checkbox me='16px' colorScheme='brandScheme' />
            <Text
              fontWeight='bold'
              color={textColor}
              fontSize='md'
              textAlign='start'>
              <a href={`/newsitem/${newsItem.url_slug}`}><br />{newsItem.title} </a> <br/>
              {newsItem.views_count} views | Posted on {newsItem.createdAt.split('T')[0].replace('-', '/')}
            </Text>
            <Icon
              ms='auto'
              as={MdDragIndicator}
              color='secondaryGray.600'
              w='24px'
              h='24px'
            />
          </Flex>
            )
          })}
       </Box>
      </Card>
    );
  }
  