// Chakra imports
import {
    Box,
    Flex,
    Text,
    Icon,
    useColorModeValue,
    Checkbox,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/card/Card.js";
  import Menu from "components/menu/MainMenu";
  import IconBox from "components/icons/IconBox";
  import CheckTable from "./CheckTable";
  import {
    columnsDataNews
  } from "../variables/columnsData";
  
  // Assets
  import { MdDragIndicator } from "react-icons/md";
  import React, { useState, useEffect } from 'react';
  import { IoMdEye } from "react-icons/io";

  //API calls
import { latestCryptoNewsByViews } from 'api/ApiUtils';
  
  export default function ShopListFunc(props) {
    const { ...rest } = props;

  const [linkDirectory, setLinkDirectory] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [recordsCount, setRecordsCount] = useState(0)
  const [limit, setLimit] = useState(50)
  const [Pages, setPages] = useState(0)

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await latestCryptoNewsByViews(pageNo, limit)
      setLinkDirectory(response.data.data.news_feeds.news_feeds)
    }
    fetchMyAPI()
  }, [])
  
    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
    const brandColor = useColorModeValue("brand.500", "brand.400");
    return (
      <Card p='20px' align='center' direction='column' w='100%' {...rest}>
        <CheckTable columnsData={columnsDataNews} tableData={linkDirectory}/>
      </Card>
    );
  }
  